<template>
  <div class="my-upload">
    <van-uploader
      v-model="fileList"
      :after-read="afterRead"
      :max-count="1"
      :before-read="beforeRead"
      :before-delete="beforeDelete"
      accept="image/png,image/jpg, image/jpeg, image/gif"
    >
      <template #default>
        <div class="upload-box">
          <div class="upload-icon-box">
            <van-icon name="plus" style="color: white" />
          </div>
          <div class="place-msg">{{ msg }}</div>
        </div>
      </template>
    </van-uploader>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue";
import { uploadCoverPic, searchFileList, deleteFile } from "@/api/file/index";
export default {
  props: {
    busiId: {
      type: [Number, String],
    },
    newBusiId: {
      type: [String, Number],
    },
    busiCat: {
      type: String,
      default: "",
    },
    groupId: {
      type: String,
      defalut: "",
    },
    isRealDeleteImg: {
      type: Boolean,
      default: true,
    },
    msg: String,
  },
  setup(props) {
    const state = reactive({
      fileList: [],
      currentFileId: 0,
      fileId: "",
      file: null,
    });
    const afterRead = (file) => {
      state.file = file.file;
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file); //接口需要传的参数
      if (props.isRealDeleteImg) {
        formData.append("busiId", props.busiId); //接口需要传的参数
      } else {
        formData.append("busiId", props.newBusiId); //接口需要传的参数
      }
      formData.append("busiCat", props.busiCat); //接口需要传的参数
      formData.append("groupId", props.groupId); //接口需要传的参数
      uploadCoverPic(formData)
        .then((data) => {
          let item = {
            url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
            fileId: data.fileId,
          };
          state.fileList.pop();
          state.fileList.push(item);
          console.log(state.fileList);
        })
        .catch((err) => {
          console.log(err);
          state.fileList.pop();
          file.status = "failed";
          file.message = "上传失败";
        });
    };
    const uploadFileToServer = (busiId) => {
      let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      if (props.busiId !== undefined) {
        formData.append("busiId", props.busiId); //接口需要传的参数
      } else {
        formData.append("busiId", busiId); //接口需要传的参数
      }
      formData.append("file", state.file); //接口需要传的参数
      formData.append("busiCat", props.busiCat); //接口需要传的参数
      formData.append("groupId", props.groupId); //接口需要传的参数
      uploadCoverPic(formData);
    };
    const beforeRead = () => {
      return true;
    };
    const beforeDelete = (file) => {
      console.log("删除====》",file.fileId)
      console.log("真的删除吗====》",props.isRealDeleteImg)
      if (file.fileId) {
        if (props.isRealDeleteImg) {
          deleteFile({ fileId: file.fileId }).then((data) => {
            if (data) {
              state.fileList = state.fileList.filter(
                (item) => item.fileId !== file.fileId
              );
            }
          });
          console.log(file.fileId);
        } else {
          state.fileList = state.fileList.filter(
            (item) => item.fileId !== file.fileId
          );
        }
      }
    };
    const searchFile = () => {
      let param = {
        busiId: props.busiId,
        busiCat: props.busiCat,
        groupId: props.groupId,
      };
      searchFileList(param).then((data) => {
        if (data) {
          data.map((bloc) => {
            let item = {
              fileId: bloc.fileId,
              url: `${process.env.VUE_APP_WATCH_FILE_API}/${bloc.url}`,
            };
            state.fileList.push(item);
          });
        }
      });
    };
    onMounted(() => {
      searchFile();
    });
    return {
      ...toRefs(state),
      afterRead,
      beforeRead,
      beforeDelete,
      uploadFileToServer,
    };
  },
};
</script>
<style lang="scss">
.my-upload {
  .van-uploader__preview {
    width: 100%;
    margin: 0;
  }
  .van-uploader {
    width: 100%;
  }
  .van-uploader__wrapper {
    width: 100%;
  }
  .van-uploader__input-wrapper {
    width: 100%;
  }
  .van-uploader__preview-image {
    width: 100%;
    height: 160px;
  }
}
</style>
<style lang="scss" scoped>
.place-msg {
  color: #666666;
  font-size: 12px;
  margin-top: 10px;
}
.my-upload {
  width: 100%;
}
.upload-box {
  display: flex;
  border-radius: 4px;
  background: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 160px;
}
.upload-icon-box {
  display: flex;
  align-items: center;
  width: 52px;
  height: 52px;
  background: #289fff;
  border-radius: 50%;
  justify-content: center;
}
</style>
