<template>
  <div ref="editor"></div>
  <div :innerHTML="content.html"></div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, reactive } from "vue";
import { uploadFile } from "@/api/file";
import WangEditor from "wangeditor";
export default {
  name: "app",
  props: {
    modelValue: String,
    busiId: {
      type: [Number, String],
    },
    busiCat: {
      type: String,
      default: "",
    },
    groupId: {
      type: String,
      defalut: "",
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const editor = ref();
    const content = reactive({
      html: "",
      text: "",
    });
    const { emit } = context;
    let instance;
    onMounted(() => {
      instance = new WangEditor(editor.value);
      Object.assign(instance.config, {
        onchange() {
          emit("update:modelValue", instance.txt.html());
        },
        zIndex: 100,
        showLinkImg: false,
        customUploadImg: (resultFiles, insertImgFn) => {
          console.log(resultFiles);
          let formData = new FormData();
          formData.append("file", resultFiles[0]);
          formData.append("busiId", props.busiId);
          formData.append("busiCat", props.busiCat);
          formData.append("groupId", props.groupId);
          uploadFile(formData).then((data) => {
            if (data) {
              let imgUrl = `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`;
              insertImgFn(imgUrl);
            }
          });
        },
      });
      instance.create();
      instance.txt.html(props.modelValue)
    });
    onBeforeUnmount(() => {
      instance.destroy();
      instance = null;
    });
    const syncHTML = () => {
      content.html = instance.txt.html();
    };
    return {
      syncHTML,
      editor,
      content,
    };
  },
};
</script>
